import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import styled from "styled-components";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const FormInput = styled.div`
  margin-bottom: 10px;
`;

const PlateButton = styled.li`
  list-style-type: none;
  width: 50%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bg};
  color: #ffffff;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PlateColor = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 80%;
  height: 90%;
  cursor: pointer;
`;

const AmountDue = styled.h4`
  font-size: 3rem;
  margin: 0.5rem;
  text-align: center;
`;

const AddMisc = ({ totalDue, handleReset }) => {
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    cash: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCalculate = (e) => {};

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Calculate Change
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <FormInput>Change Due:</FormInput>
          <AmountDue>{totalDue}</AmountDue>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="warning"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleReset();
              setOpen(false);
            }}
          >
            Next Transaction
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddMisc;
