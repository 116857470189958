import React from "react";
import FloatedContent from "./FloatedContent";
import PageTitle from "./PageTitle";

const Takeout = () => {
  return (
    <FloatedContent>
      <PageTitle title="Send Takeout" />
    </FloatedContent>
  );
};

export default Takeout;
