import React from "react";
import styled from "styled-components";
import GenkiLogo from "../assets/images/logo.png";
import { NavLink } from "react-router-dom";

const HeaderBar = styled.header`
  height: 50px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  height: 100%;
`;

const Links = styled.div`
  display: flex;
  gap: 30px;
  padding-right: 20px;
`;

const Link = styled(NavLink)`
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Header = () => {
  return (
    <HeaderBar>
      <Logo src={GenkiLogo} alt="Genki Logo" />
      <Links>
        <Link to="/">Check</Link>
        <Link to="/takeout">Take-Out</Link>
      </Links>
    </HeaderBar>
  );
};

export default Header;
