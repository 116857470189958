import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import styled from "styled-components";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const FormInput = styled.div`
  margin-bottom: 10px;
`;

const PlateButton = styled.li`
  list-style-type: none;
  width: 50%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bg};
  color: #ffffff;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PlateColor = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 80%;
  height: 90%;
  cursor: pointer;
`;

const AddMisc = ({ checkItems, setCheckItems }) => {
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    id:
      checkItems.miscItems.length > 0
        ? checkItems.miscItems[checkItems.miscItems.length - 1].id + 1
        : 1,
    price: "",
    qty: "1",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAdd = (e) => {
    e.preventDefault();
    setOpen(false);
    console.log(formData);
    setCheckItems({
      ...checkItems,
      miscItems: [...checkItems.miscItems, formData],
    });
    setFormData({
      price: "",
      qty: "1",
    });
  };

  return (
    <>
      <PlateButton bg="#000000" onClick={() => setOpen(true)}>
        <PlateColor>Add Misc Item</PlateColor>
      </PlateButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleAdd}>
          <DialogContent>
            <FormInput>
              <TextField
                id="outlined-basic"
                label="Price"
                variant="outlined"
                name="price"
                value={formData.price}
                onChange={handleChange}
                fullWidth
                required
              />
            </FormInput>
            <FormInput>
              <TextField
                id="outlined-basic"
                label="QTY"
                variant="outlined"
                type="number"
                value={formData.qty}
                onChange={handleChange}
                name="qty"
              />
            </FormInput>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit" onClick={() => {}}>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddMisc;
