import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Header from "./components/Header";
import Check from "./components/Check";
import Takeout from "./components/Takeout";

const Content = styled.main`
  width: 100%;
  padding: 80px 10px;
  @media (min-width: 768px) {
    padding: 80px 40px;
  }
`;

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Content>
        <Routes>
          <Route index element={<Check />} />
          <Route path="takeout" element={<Takeout />} />
        </Routes>
      </Content>
    </BrowserRouter>
  );
}

export default App;
