import React, { useState } from "react";
import FloatedContent from "./FloatedContent";
import PageTitle from "./PageTitle";
import styled from "styled-components";
import SecondaryTitle from "./SecondaryTitle";
import AddMisc from "./AddMisc";
import Button from "@mui/material/Button";
import CalculateChange from "./CalculateChange";
import TextField from "@mui/material/TextField";

const PlateButtonHolder = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

const FormInput = styled.div`
  margin-bottom: 10px;
`;

const PlateButton = styled.li`
  list-style-type: none;
  width: 50%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bg};
  color: #ffffff;
  font-weight: bold;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PlateColor = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 80%;
  height: 90%;
  cursor: pointer;
`;

const PlusMinus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 90%;
  width: 20%;
  cursor: pointer;
`;

const Receipt = styled.ul`
  margin: 20px 0;
  padding: 0;
`;

const ReceiptItem = styled.li`
  list-style-type: none;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Check = () => {
  const [tax, setTax] = useState("4");

  const [cashIn, setCashIn] = useState("");

  const [formData, setFormData] = useState({
    cash: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [checkItems, setCheckItems] = useState({
    items: [
      { color: "blue", qty: 0 },
      { color: "yellow", qty: 0 },
      { color: "green", qty: 0 },
      { color: "red", qty: 0 },
      { color: "silver", qty: 0 },
      { color: "black", qty: 0 },
      { color: "seasonal spec", qty: 0 },
      { color: "keiki drink", qty: 0 },
      { color: "adult drink", qty: 0 },
    ],
    miscItems: [],
  });

  const plates = [
    { color: "blue", price: 1.85, hex: "#4193d5" },
    { color: "yellow", price: 2.75, hex: "#f3bb44" },
    { color: "green", price: 3.65, hex: "#4aa459" },
    { color: "red", price: 4.55, hex: "#da3832" },
    { color: "silver", price: 5.45, hex: "#808285" },
    { color: "black", price: 6.35, hex: "#221f20" },
    { color: "seasonal spec", price: 11.0, hex: "#0d4d3f" },
    { color: "keiki drink", price: 1.95, hex: "#4c4d0d" },
    { color: "adult drink", price: 2.75, hex: "#2f0d4d" },
  ];

  const handleColorClick = (color) => {
    setCheckItems({
      ...checkItems,
      items: checkItems.items.map((item) => {
        if (item.color !== color) return item;
        return {
          ...item,
          qty: item.qty + 1,
        };
      }),
    });
  };

  const handleMinusClick = (color) => {
    setCheckItems({
      ...checkItems,
      items: checkItems.items.map((item) => {
        if (item.color !== color) return item;
        return {
          ...item,
          qty: item.qty === 0 ? 0 : item.qty - 1,
        };
      }),
    });
  };

  const handleReset = () => {
    setCheckItems({
      items: [
        { color: "blue", qty: 0 },
        { color: "yellow", qty: 0 },
        { color: "green", qty: 0 },
        { color: "red", qty: 0 },
        { color: "silver", qty: 0 },
        { color: "black", qty: 0 },
        { color: "seasonal spec", qty: 0 },
        { color: "keiki drink", qty: 0 },
        { color: "adult drink", qty: 0 },
      ],
      miscItems: [],
    });
    setFormData({
      cash: "",
    });
  };

  const renderPlateButtons = () => {
    return plates.map((plate, index) => {
      return (
        <PlateButton key={index} bg={plate.hex}>
          <PlateColor onClick={() => handleColorClick(plate.color)}>
            {plate.color}
          </PlateColor>
          <PlusMinus onClick={() => handleMinusClick(plate.color)}>
            {checkItems.items.find((item) => item.color === plate.color).qty}
          </PlusMinus>
        </PlateButton>
      );
    });
  };

  const renderNormalItems = checkItems.items.map((item, index) => {
    const subTotal =
      item.qty * plates.find((plate) => plate.color === item.color).price;
    if (item.qty > 0) {
      return (
        <ReceiptItem key={index}>
          <span>
            <b>{item.color}</b> ({item.qty})
          </span>
          <span>${subTotal.toFixed(2)}</span>
        </ReceiptItem>
      );
    }
    return null;
  });

  const renderMiscItems = () => {
    if (checkItems.miscItems.length === 0) return null;
    return checkItems.miscItems.map((miscItem, index) => {
      const subTotal = miscItem.qty * miscItem.price;
      console.log(miscItem);
      return (
        <ReceiptItem key={index}>
          <span>
            <b>Misc Item</b> ({miscItem.qty})
          </span>
          <span>${subTotal.toFixed(2)}</span>
        </ReceiptItem>
      );
    });
  };

  const calculateSubtotal = () => {
    let total = 0;
    checkItems.items.forEach((item) => {
      total +=
        plates.find((plate) => plate.color === item.color).price * item.qty;
    });
    checkItems.miscItems.forEach((miscItem) => {
      total += miscItem.qty * miscItem.price;
    });
    return total;
  };

  const calculateTax = () => {
    const calculatedTax = (tax / 100) * calculateSubtotal();
    return calculatedTax;
  };

  const calculateTotal = () => {
    return calculateSubtotal() + calculateTax();
  };

  const calculateTotalDue = () => {
    if (formData.cash !== "") {
      return `$${(formData.cash - calculateTotal()).toFixed(2)}`;
    }
    return "No cash amount provided.";
  };

  return (
    <FloatedContent>
      <PageTitle title="Calculate Check" />
      <PlateButtonHolder>
        {renderPlateButtons()}
        <AddMisc checkItems={checkItems} setCheckItems={setCheckItems} />
      </PlateButtonHolder>
      <SecondaryTitle title="Check" />
      <Receipt>
        {renderNormalItems}
        {renderMiscItems()}
        <ReceiptItem>
          <span>
            <b>Subtotal:</b>
          </span>
          <span>${calculateSubtotal().toFixed(2)}</span>
        </ReceiptItem>
        <ReceiptItem>
          <span>
            <b>Tax:</b>
          </span>
          <span>${calculateTax().toFixed(2)}</span>
        </ReceiptItem>
        <ReceiptItem>
          <span>
            <b>Total:</b>
          </span>
          <span>${calculateTotal().toFixed(2)}</span>
        </ReceiptItem>
      </Receipt>
      <form onSubmit={(e) => e.preventDefault()}>
        <FormInput>
          <TextField
            id="outlined-basic"
            label="Cash Tendered"
            variant="outlined"
            value={formData.cash}
            onChange={handleChange}
            fullWidth
            name="cash"
            required
          />
        </FormInput>
        <FormInput>
          <CalculateChange
            totalDue={calculateTotalDue()}
            handleReset={handleReset}
            total={calculateTotal()}
          />
        </FormInput>
      </form>
      <FormInput>
        <Button
          fullWidth
          variant="contained"
          color="error"
          onClick={handleReset}
        >
          Reset
        </Button>
      </FormInput>
    </FloatedContent>
  );
};

export default Check;
